import { graphql, Link } from 'gatsby';
import React, { useState } from 'react';
import Seo from '../components/base/Seo';
import FullPageFrom from '../components/organisms/FullPageForm';

const ForgottenPasswordPage = ({ data: { data } }) => {
  const [success, setSuccess] = useState(false);

  return (
    <>
      <Seo post={data} />

      <FullPageFrom>
        {success ? (
          <>
            <h1 className="mb-3 u-h4">We’ve sent you a password reset</h1>
            <p className="mb-8 leading-7 text-body-copy">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Magna sit
              pellentesque elit.
            </p>
            <Link to="/log-in" className="u-form-button">
              Return to Login
            </Link>
          </>
        ) : (
          <>
            <h1 className="mb-5 u-h4">Forgot your password?</h1>

            <form className="pt-6 space-y-4 pb-9">
              <div>
                <input
                  name="email"
                  type="email"
                  placeholder="Email Address"
                  className="u-form-input"
                />
              </div>

              <button
                className="u-form-button"
                onClick={() => setSuccess(true)}
              >
                Reset Password
              </button>
            </form>

            <Link
              to="/log-in"
              className="text-sm underline transition text-body-copy opacity-60 hover:opacity-100 focus:opacity-100 focus:outline-none"
            >
              Already have an account?
            </Link>
          </>
        )}
      </FullPageFrom>
    </>
  );
};

export const pageQuery = graphql`
  query ForgottenPasswordPageQuery {
    data: wpPage(slug: { eq: "forgotten-password" }) {
      title
      ...SEO
    }
  }
`;

export default ForgottenPasswordPage;
